import * as React from "react";
import { graphql } from "gatsby";
import Cubes from "../../../../modules/cubes/index";

const CubePage = () => {
  let expired = false;
  let cubeType = 0;

  if (typeof window === "object") {
    const path = window.location.href.split("/");
    cubeType = parseInt(path[4]);
    expired = parseInt(path[5]) === 1 ? true : false;
  }

  return <Cubes expired={expired} cubeType={cubeType} />;
};

export default CubePage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
