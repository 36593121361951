import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from "../../services/constants";
// import { defaultDisclaimer, defaultInfo } from '../../services/constants'
import ApiService from "../../services/api_services";
import helper from "../../services/helper";
import Card from "./Card";
import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import "../../assets/select.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Web3 from "web3";
import config, { UScurrencyFormatter } from "../../config/config";
//import "datatables.net";
// import ApexCharts from 'apexcharts'
import PINKSALE from "../../assets/images/partners/pinksale.png";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import parse from "html-react-parser";
import { t } from "i18next";
import { hotjar } from "react-hotjar";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

function cubeTypeTitle(c) {
  if (c === 0) {
    // return t("ALGO TRADING CUBES");
    return "ALGO TRADING CUBES";
  } else if (c === 1) {
    //return t("FIXED INCOME CUBES");
    return "FIXED INCOME CUBES";
  } else if (c === 2) {
    //return t("RACE TRADING CUBES");
    return "RACE TRADING CUBES";
  } else {
    //return t("CUBES");
    return "CUBES";
  }
}

const cubeTypeDescription = (c) => {
  if (c === 0) {
    return "Algo Trading Cubes are investment strategies that enable users to earn a variable return on their crypto assets. Algo Trading investment strategies are based on statistics, with a multidimensional approach stemming from but not limited to arbitrage. There are a variety of Algo Trading Cubes, each with their own individual details pertaining to the investment.";
  } else if (c === 1) {
    return "Fixed Income Cubes are investment strategies that enable users to earn a fixed return on stablecoins and other crypto assets. There are a variety of Fixed Income Cubes, each with their own individual details pertaining to the investment.";
  } else if (c === 2) {
    return "Race Trading Cubes enable users to earn a variable return on their crypto assets. Race Trading Cubes take a relatively more aggressive approach to investing, with users able to speculate on the future price of an asset by selecting long or short positions. Users may choose from a wide variety of Cubes with different assets, target prices, liquidation prices, leverage, and more.";
  } else {
    return "";
  }
};

function Cubes(props) {
  // const { t } = props
  //const cubeType = +props.match.params.cubeType;
  const cubeType = props.cubeType;
  const [selectedToken, setSelectedToken] = useState("");

  useEffect(() => {
    $("#options-view-button").prop("checked", false);
  }, [selectedToken]);

  return (
    <>
      <section className="page-header" style={{ marginBottom: "40px" }}>
        <div className="page-header-bg"></div>

        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="page-header-shape-3"></div>

        <div className="container text-center">
          <div className="page-header__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className=" ">
                  <div className="cube">
                    <div className="s1"></div>
                    <div className="s2"></div>
                    <div className="s3"></div>
                    <div className="s4"></div>
                    <div className="s5"></div>
                    <div className="s6"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <h2>{cubeTypeTitle(cubeType)}</h2>
                <hr />
                <p>{cubeTypeDescription(cubeType)}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {cubeType !== 0 && false && (
        <section>
          <form id="app-cover" style={{ marginBottom: "20px", zIndex: "9" }}>
            <div id="select-box">
              <input type="checkbox" id="options-view-button" />
              <div id="select-button" className="brd">
                <div id="selected-value">
                  <span>Filter by Asset</span>
                </div>
                <div id="chevrons">
                  <i className="fas fa-chevron-up"></i>
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
              <div id="options">
                <div
                  className="option"
                  key={"token_ALL"}
                  onClick={() => setSelectedToken("")}
                >
                  <input
                    className="s-c top"
                    type="radio"
                    name="cube"
                    value={"token_ALL"}
                  />
                  <input
                    className="s-c bottom"
                    type="radio"
                    name="cube"
                    value={"token_ALL"}
                  />
                  <span className="label">
                    <div className="row">
                      <div className="col-md-4">ALL</div>
                    </div>
                  </span>
                  <span className="opt-val">ALL</span>
                </div>
                {cubeType === 1 &&
                  Constants.tokensArrayFixed.map((item) => (
                    <div
                      className="option"
                      key={"token_" + item.t.symbol}
                      onClick={() => setSelectedToken(item.t.symbol)}
                    >
                      <input
                        className="s-c top"
                        type="radio"
                        name="cube"
                        value={"token_" + item.t.symbol}
                      />
                      <input
                        className="s-c bottom"
                        type="radio"
                        name="cube"
                        value={"token_" + item.t.symbol}
                      />
                      <span className="label">
                        <div className="row">
                          <div className="col-md-6">{item.t.symbol}</div>
                          <div className="col-md-6">
                            {item.comingSoon ? "Coming Soon" : ""}
                          </div>
                        </div>
                      </span>
                      <span className="opt-val">{item.t.symbol}</span>
                    </div>
                  ))}
                {cubeType === 2 &&
                  Constants.tokensArrayRace.map((item) => (
                    <div
                      className="option"
                      key={"token_" + item.t.symbol}
                      onClick={() => setSelectedToken(item.t.symbol)}
                    >
                      <input
                        className="s-c top"
                        type="radio"
                        name="cube"
                        value={"token_" + item.t.symbol}
                      />
                      <input
                        className="s-c bottom"
                        type="radio"
                        name="cube"
                        value={"token_" + item.t.symbol}
                      />
                      <span className="label">
                        <div className="row">
                          <div className="col-md-6">{item.t.symbol}</div>
                          <div className="col-md-6">
                            {item.comingSoon ? "Coming Soon" : ""}
                          </div>
                        </div>
                      </span>
                      <span className="opt-val">{item.t.symbol}</span>
                    </div>
                  ))}
                <div id="option-bg"></div>
              </div>
            </div>
          </form>
        </section>
      )}

      <section id="cubes">
        {/* <Card {...props} />; */}
        {Constants.cubes
          .filter(
            (item) =>
              item.enabled &&
              item.cubeType === cubeType &&
              (item.token.symbol === selectedToken || selectedToken === "")
          )
          .sort((a, b) => a.listOrder - b.listOrder)
          .map((cube) => (
            <Card key={cube.index} {...cube} {...props} />
          ))}
      </section>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return { saveInfo: (data) => dispatch(data) };
}

// export default connect(
//   null,
//   mapDispatchToProps
// )(withTranslation()(withRouter(Cubes)));
export default withTranslation()(Cubes);
