import React, { useState, useEffect } from "react";

import { Link } from "gatsby";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from "../../services/constants";
// import { defaultDisclaimer, defaultInfo } from '../../services/constants'
import ApiService from "../../services/api_services";
import helper from "../../services/helper";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import "../../assets/select.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Web3 from "web3";
import config, { UScurrencyFormatter } from "../../config/config";
//import "datatables.net";
// import ApexCharts from 'apexcharts'
import PINKSALE from "../../assets/images/partners/pinksale.png";
import { hotjar } from "react-hotjar";
import parse from "html-react-parser";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    console.log(item);
    images[item.replace("./", "")] = r(item);
  });
  console.log(images);
  return images;
}

const isWindow = typeof window === "object";
class Card extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDisclaimerOpen: false,
      isInfoOpen: false,
      currentPrice: 0,
      cubeAssetAmount: 1,
      validCube: false,
      web3Available: false,
      cubeEnabled: true,
      totalAccount: 0,
      totalPayouts: 0,
      performancePlatform: 0,
      AUM: 0,
      TVL: 0,
      TPV: 0,
      localExpiration: false,
      priceExpired: false,
      lockupPeriodString: "",
      lockupPeriod: 0,
      investorsCount: 0,
      investorsAmount: 0,
      investorsList: [],
      tradesCount: 0,
      tradesList: [],
      table: null,
      tableLoaded: false,
      chartLoaded: false,
      cubeChartDay: [],
      cubeChartDayReverse: [],
      cryptoLogos: importAll(
        require.context(
          "../../assets/images/cryptos",
          false,
          /\.(png|jpe?g|svg)$/
        )
      ),
      formattedDescription: this.formatDescription(props.description),
      walletAddress:
        typeof localStorage == "object" ? localStorage.walletAddress : "",
      coinFutures: false,
      expiredList: +props.expired === 1,
      cubeType: props.cubeType,
    };

    this.disclaimerRef = null;
    this.infoRef = null;
  }

  formatDescription(d) {
    var result = d;
    const lines = d.split(";");
    if (lines.length > 1) {
      result = "";
      for (let l of lines) {
        if (result !== "") {
          result += "<br/>";
        }
        result += l;
      }
    }

    return result;
  }

  setCurrentPrice() {
    const url =
      "https://dapi.binance.com/dapi/v1/ticker/bookTicker?symbol=" +
      this.props.binanceSymbol;
    const apiService = new ApiService();
    apiService
      .fetch(url, { method: "GET" })
      .then((response) => {
        if (response && response[0].bidPrice) {
          this.setState({ currentPrice: +response[0].bidPrice });
        }
      })
      .catch((error) => {
        console.log("error getting price", error);
      });
  }

  setCube(c, id) {
    this.isValidCube(c, id).then((result) => {
      this.setState({ validCube: result });
    });
    if (c === 2 && this.state.validCube && !this.state.expiredList) {
      this.setCurrentPrice();
      this.tickerPrice = setInterval(() => {
        this.setCurrentPrice();
      }, 60000);
    } else {
      if (this.tickerPrice) {
        clearInterval(this.tickerPrice);
      }
    }
  }

  componentDidMount() {
    const { token, id } = this.props;
    const { cubeType } = this.state;
    jQueryFunc();
    window.scrollTo(0, 0);
    //loadPage(this.props.location.pathname + this.props.location.search);
    this.setCube(cubeType, id);

    const c = cubeType;

    if (c === 0) {
      //this.setState({ cubeTypeTitle: t("Algo Trading Cube") });
      this.setState({ cubeTypeTitle: "Algo Trading Cube" });
    } else if (c === 1) {
      //this.setState({ cubeTypeTitle: t("Fixed Income Cube") });
      this.setState({ cubeTypeTitle: "Fixed Income Cube" });
    } else if (c === 2) {
      //this.setState({ cubeTypeTitle: t("Race Trading Cube") });
      this.setState({ cubeTypeTitle: "Race Trading Cube" });
    } else {
      //this.setState({ cubeTypeTitle: t("Cube") });
      this.setState({ cubeTypeTitle: "Cube" });
    }
    if (token.symbol !== "USDT" && token.symbol !== "BUSD") {
      this.setState({ coinFutures: true });
    }

    this.getInvestors();

    //hotjar.initialize("3117947", 6);
  }

  updateTradesList = () => {
    if (!this.state.table) {
      this.getTrades();
    }
  };

  updateDailyChart = () => {
    if (!this.state.chartLoaded) {
      this.getDailyChart();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expired !== this.props.expired) {
      this.setState({ expiredList: +this.props.expired === 1 });
      this.setCube(this.props.cubeType, this.props.id);
    }
  }

  componentWillUnmount() {
    clearInterval(this.tickerPrice);
  }

  isValidCube = async (cubeType, cubeID) => {
    const localExpiredList = this.props.expired;

    const now = new Date();
    var enabledCube = false;
    var investedIn = false;
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      let abi = config.FundManagerABIs[cubeType];
      var FundManagerContract = new web3.eth.Contract(
        abi,
        config.FundManagerContracts[cubeType]
      );

      var cubeInfo = await FundManagerContract.methods.cubeInfo(cubeID).call();

      let assetAmount = parseInt(cubeInfo.totals.assetAmount || 0) / 1e18;

      let enabled = cubeInfo.enabled;

      let targetPrice = parseInt(cubeInfo.targetPrice || 0) / 1e18;
      let stopPrice = parseInt(cubeInfo.stopPrice || 0) / 1e18;
      let lastAssetPrice = parseInt(cubeInfo.lastAssetPrice || 0) / 1e18;

      let priceExpired =
        (targetPrice > stopPrice &&
          lastAssetPrice !== 0 &&
          (lastAssetPrice >= targetPrice || lastAssetPrice <= stopPrice)) ||
        (targetPrice < stopPrice &&
          lastAssetPrice !== 0 &&
          (lastAssetPrice <= targetPrice || lastAssetPrice >= stopPrice));

      this.setState({
        cubeAssetAmount: assetAmount,
        cubeEnabled: enabled,
        priceExpired: priceExpired,
      });

      enabledCube =
        now.getTime() / 1000 < cubeInfo.times.expiration &&
        cubeInfo.enabled /*&& assetAmount !== 0*/;

      if (this.state.walletAddress && !localExpiredList) {
        var userInfo = await FundManagerContract.methods
          .userInfo(cubeID, this.state.walletAddress)
          .call();
        var userInvestedAmount = parseInt(userInfo.assetAmount || 0);
        if (userInfo.unlockedValues) {
          userInvestedAmount += parseInt(
            userInfo.unlockedValues.assetAmount || 0
          );
        }
        if (userInfo.earlyUnlockedValues) {
          userInvestedAmount += parseInt(
            userInfo.earlyUnlockedValues.assetAmount || 0
          );
        }
        if (userInvestedAmount !== 0) {
          investedIn = true;
        }
      }
      this.setState({ web3Available: true });
      let expirationDate = new Date(parseInt(this.props.expiration) * 1000);

      this.setLockupPeriod(expirationDate);
    } else {
      this.setState({ web3Available: false });
      let expirationDate = new Date(parseInt(this.props.expiration) * 1000);
      this.setLockupPeriod(expirationDate);
      enabledCube = now < expirationDate && this.props.enabled;
    }
    if (localExpiredList) {
      enabledCube = !enabledCube;
    }
    return enabledCube || investedIn;
  };

  setLockupPeriod = (d) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    let now = new Date();
    if (now > d || !this.state.cubeEnabled || this.state.priceExpired) {
      this.setState({ localExpiration: true, lockupPeriodString: "expired" });
    } else {
      let days = Math.floor((d - now) / _MS_PER_DAY);
      this.setState({
        localExpiration: false,
        lockupPeriodString: "approx. " + days + " days",
        lockupPeriod: days,
      });
    }
  };

  getInvestors = () => {
    const { cubeType, id } = this.props;
    const url = "https://admin.hyperdex.cloud/graphql"; // `https://test.hyperdex.cloud/web/cube/${cubeType}/${id}/investorslist`;
    const apiService = new ApiService();
    // apiService.fetch(url, { method: 'GET' })
    const query =
      "{ ActiveInvestorsList(cubeType: " +
      cubeType +
      ", cubeID: " +
      id +
      ") {userAddress,currentInvestment} }";
    apiService
      .fetchGraphQL(url, {
        method: "POST",
        body: JSON.stringify({ query: query }),
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.ActiveInvestorsList &&
          helper.isArray(response.data.ActiveInvestorsList)
        ) {
          let filteredResult = response.data.ActiveInvestorsList.filter(
            (item) => item.currentInvestment > 0
          );
          let investorsAmount = filteredResult.reduce(
            (previousValue, currentValue) =>
              previousValue + +currentValue.currentInvestment,
            0
          );
          this.setState({
            investorsCount: filteredResult.length,
            investorsList: filteredResult,
            investorsAmount: investorsAmount,
          });
        }
      })
      .catch((error) => {
        console.log("error getInvestors", error);
      });
  };

  getTrades = () => {
    const { cubeType, id, token } = this.props;
    const { table, coinFutures } = this.state;
    const url = "https://admin.hyperdex.cloud/graphql";
    const apiService = new ApiService();
    const query =
      "{ TradesList(cubeType: " +
      cubeType +
      ", cubeID: " +
      id +
      ") {tradeID,timestamp,side,symbol,qty,price,realizedPnl,commission} }";
    apiService
      .fetchGraphQL(url, {
        method: "POST",
        body: JSON.stringify({ query: query }),
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.TradesList &&
          helper.isArray(response.data.TradesList)
        ) {
          var tradesList = response.data.TradesList;
          if (coinFutures) {
            tradesList = tradesList.filter(
              (item) =>
                item.symbol.slice(0, token.symbol.length) === token.symbol
            );
          }
          tradesList.forEach((element) => {
            var date = new Date(element.timestamp);
            element.date = date.toGMTString();
          });
          this.setState({
            tradesCount: response.data.TradesList.length,
            tradesList: response.data.TradesList,
          });
          if (table) {
            table.destroy();
          }
          let localTable = $("#tradestable_" + id).DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.24/i18n/English.json",
            },
            paging: true,
            pageLength: 10,
          });
          this.setState({ table: localTable, tableLoaded: true });
          $("#tradestable_" + id).DataTable({
            initComplete: function (settings, json) {},
          });
        }
      })
      .catch((error) => {
        console.log("error getTrades", error);
      });
  };

  getDailyChart = async () => {
    const { cubeType, id /*, index*/ } = this.props;
    const url = "https://admin.hyperdex.cloud/graphql";
    const apiService = new ApiService();
    const query =
      "{ CubeChartDayFull(cubeType: " +
      cubeType +
      ", cubeID: " +
      id +
      ") {timestamp,sharePrice} }";
    apiService
      .fetchGraphQL(url, {
        method: "POST",
        body: JSON.stringify({ query: query }),
      })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.CubeChartDayFull &&
          helper.isArray(response.data.CubeChartDayFull)
        ) {
          let data = response.data.CubeChartDayFull.map((element) => {
            const dt = new Date(element.timestamp * 1000);
            const day = this.timeConverter(element.timestamp);
            return {
              x: dt.toISOString().split("T")[0],
              y: element.sharePrice.toFixed(4),
              day: day,
              sharePrice: element.sharePrice.toFixed(4),
              variation: ((element.sharePrice - 1) * 100).toFixed(2),
            };
          });
          const uniqueDays = [];
          const uniqueData = data.filter((element) => {
            const isDuplicate = uniqueDays.includes(element.day);
            if (!isDuplicate) {
              uniqueDays.push(element.day);
              return true;
            }
            return false;
          });
          this.setState({
            cubeChartDay: uniqueData,
            cubeChartDayReverse: uniqueData.reverse(),
          });
          // this.refreshChart("#graph_" + index, data, false)
        }
        this.setState({ chartLoaded: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  timeConverter = (timestamp) => {
    var a = new Date(timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // var year = a.getFullYear();
    var month = months[a.getUTCMonth()];
    var date = a.getUTCDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var time = month + "-" + date;
    return time;
  };

  render() {
    const {
      index,
      id,
      info,
      disclaimer,
      token,
      yearlyReturn,
      hyperBonus,
      hyperTokenYearlyReturn,
      cubeType,
      entryFee,
      performanceFee,
      t,
      expiration,
      targetPrice,
      stopPrice,
      borderPrice,
      riskLevel,
      isPinksale,
      pinksaleText,
    } = this.props;

    const {
      currentPrice,
      cubeAssetAmount,
      web3Available,
      cubeEnabled,
      localExpiration,
      lockupPeriodString,
      validCube,
      investorsCount,
      investorsAmount,
      investorsList,
      tradesCount,
      tradesList,
      cubeTypeTitle,
      cubeChartDayReverse,
      cryptoLogos,
      lockupPeriod,
      formattedDescription,
      priceExpired,
      coinFutures,
      tableLoaded,
      chartLoaded,
    } = this.state;
    let aprBase = (yearlyReturn / 100).toFixed(2);
    let maxCalculatedLoss = 0;
    if (cubeType === 2) {
      if (currentPrice) {
        let raceProfit =
          (((1 / currentPrice - 1 / targetPrice) * stopPrice) /
            (currentPrice - stopPrice)) *
          currentPrice *
          riskLevel;
        if (raceProfit <= 0) {
          aprBase = 0;
        } else {
          aprBase = (raceProfit * 100).toFixed(2);
        }
        let calcLoss =
          (((1 / currentPrice - 1 / stopPrice) * stopPrice) /
            (currentPrice - stopPrice)) *
          currentPrice *
          riskLevel;
        maxCalculatedLoss = (calcLoss * 100).toFixed(2);
        if (maxCalculatedLoss > 100) maxCalculatedLoss = 100;
      }
    }
    let entryFeeBase = (entryFee / 100).toFixed(2);
    let perfFeeBase = (performanceFee / 100).toFixed(2);
    var aprHYP = 0;
    // var perfFeeHyper = 0
    var aprHyper = 0;
    let expirationDate = new Date(parseInt(expiration) * 1000);
    if (cubeType === 1) {
      aprHyper = ((yearlyReturn + hyperBonus) / 100).toFixed(2);
      // perfFeeHyper = perfFeeBase
      aprHYP = (hyperTokenYearlyReturn / 100).toFixed(2);
    } else {
      aprHyper = aprBase;
      // perfFeeHyper = ((performanceFee - hyperBonus) / 100).toFixed(2)
      aprHYP = aprBase;
    }
    var netAprBase = 0;
    var netAprHyper = 0;
    var tradingPath = "";
    if (cubeType === 0) {
      tradingPath = "algo";
      netAprBase = (
        ((10000 - entryFee) * yearlyReturn * (10000 - performanceFee)) /
        1e10
      ).toFixed(2);
      netAprHyper = (
        ((10000 - entryFee) *
          yearlyReturn *
          (10000 - performanceFee + hyperBonus)) /
        1e10
      ).toFixed(2);
    } else if (cubeType === 1) {
      tradingPath = "fix";
      netAprBase = (((10000 - entryFee) * yearlyReturn) / 1e6).toFixed(2);
      netAprHyper = (
        ((10000 - entryFee) * (yearlyReturn + hyperBonus) +
          (10000 - entryFee) * hyperTokenYearlyReturn) /
        2e6
      ).toFixed(2);
    } else if (cubeType === 2) {
      tradingPath = "race";
      netAprBase = (((10000 - entryFee) * +aprBase) / 1e4).toFixed(2);
      netAprHyper = (((10000 - entryFee) * +aprBase) / 1e4).toFixed(2);
    }

    return (
      /*validCube ?*/

      <>
        <section
          className="hyperdex_cubes"
          id="cubes"
          style={{
            visibility: validCube ? "visible" : "hidden",
            height: validCube ? "auto" : "0",
          }}
        >
          <div className="container text-center"></div>
          <div className="container">
            <div
              className="hyperdex_cubes__tab-box tabs-box"
              style={{ zIndex: "0" }}
            >
              <ul className="tab-buttons clearfix list-unstyled">
                <li data-tab={"#cubes_" + index} className="tab-btn">
                  <span style={{ display: "flex" }}>
                    <div style={{ width: "48px", textAlign: "left" }}>
                      <img
                        style={{
                          width: "48px",
                          height: "48px",
                          backgroundColor: "white",
                          padding: "5px",
                          borderRadius: "50%",
                        }}
                        src={
                          cryptoLogos[token.symbol.toLowerCase() + ".png"]
                            .default
                        }
                        alt={token.symbol}
                      />
                      {isPinksale && (
                        <img
                          style={{
                            marginTop: "1em",
                            width: "48px",
                            height: "48px",
                            backgroundColor: "white",
                            padding: "5px",
                            borderRadius: "50%",
                          }}
                          src={PINKSALE}
                          alt="PinkSale"
                        />
                      )}
                    </div>
                    <div style={{ textAling: "center", paddingLeft: "5px" }}>
                      {parse(formattedDescription)}
                      {cubeEnabled && !localExpiration && !priceExpired ? (
                        <>
                          <h5 style={{ lineHeight: "0.8em" }}>
                            <b style={{ color: "#b0ffa0" }}>
                              {cubeType === 1 ? "APR" : "Exp"} {netAprBase}%
                            </b>
                          </h5>
                          {isWindow && window.ethereum && (
                            <h5 style={{ lineHeight: "0.8em" }}>
                              <b style={{ color: "#b0ffa0" }}>
                                ({lockupPeriod} days)
                              </b>
                            </h5>
                          )}
                          {isPinksale && pinksaleText && (
                            <h5
                              style={{
                                marginTop: "1em",
                                fontSize: "0.6em",
                                lineHeight: "1em",
                                color: "#ffa0f8",
                                paddingBottom: "0",
                              }}
                            >
                              {pinksaleText}
                            </h5>
                          )}
                        </>
                      ) : (
                        <h5 style={{ color: "gray" }}>expired</h5>
                      )}
                      {/*cubeEnabled && !localExpiration ? <></> : <h5 style={{ color: 'gray' }}>expired</h5>*/}
                    </div>
                  </span>
                </li>
                <li data-tab={"#investors_" + index} className="tab-btn">
                  <span>
                    {t("Investors List")}

                    <h5 style={{ lineHeight: "0.8em", color: "#ffa0f8" }}>
                      {investorsCount}{" "}
                      {investorsCount === 1 ? t("User") : t("Users")}
                    </h5>
                    <h5 style={{ lineHeight: "0.8em", color: "#ffa0f8" }}>
                      {investorsCount !== 0 ? (
                        <>
                          {UScurrencyFormatter(investorsAmount || 0)}{" "}
                          {token?.symbol}
                        </>
                      ) : (
                        ""
                      )}
                    </h5>
                  </span>
                </li>

                {cubeType === 0 && (
                  <li
                    data-tab={"#chart_" + index}
                    className="tab-btn"
                    onMouseUp={() => this.updateDailyChart()}
                  >
                    {/* <span>{t("Chart")}</span> */}
                    <span>Chart</span>
                  </li>
                )}
                <li data-tab={"#about_" + index} className="tab-btn">
                  {/* <span>{t("Cube Info")}</span> */}
                  <span>{"Cube Info"}</span>
                </li>
                <li data-tab={"#information_" + index} className="tab-btn">
                  {/* <span>{t("About")}</span> */}
                  <span>About</span>
                </li>
              </ul>
              <div className="tabs-content">
                {/* tab */}
                <div className="tab" id={"cubes_" + index}>
                  <div className="tabs-content__inner">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="logohyp">
                          <div id="" className="square hidden s-top"></div>
                          <div id="" className="square hidden s-bottom"></div>
                        </div>
                        <div className="tabs-content__left">
                          <h2
                            className=""
                            style={{
                              textAlign: "center",
                              color: "white",
                              marginBottom: "0.5em",
                            }}
                          >
                            {cubeTypeTitle}
                            <br />
                            &nbsp;
                          </h2>
                          <ul className="list-unstyled tabs-content__points text-center">
                            <li>
                              <div className="text">
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {token?.symbol}{" "}
                                  {cubeType !== 1
                                    ? //   t("Exp. Return") : t("APR")}
                                      "Exp. Return"
                                    : "APR"}{" "}
                                  :{" "}
                                  <b style={{ color: "#ffa0f8" }}>{aprBase}%</b>
                                  {cubeType !== 1 && <sup>*</sup>}
                                </h2>
                                {cubeType === 2 && (
                                  <h5
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {/*{t("total liquidation price")} : { stopPrice }*/}
                                    {/* {t("Maximum loss")}  */}
                                    {"Maximum loss"}: {maxCalculatedLoss} %
                                  </h5>
                                )}

                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  <b> -- </b>{" "}
                                </h2>
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {cubeType !== 1
                                    ? // ? t("Net Exp. Return")
                                      // : t("Net APR")}{" "}
                                      "Net Exp. Return"
                                    : "Net APR"}{" "}
                                  :{" "}
                                  <b style={{ color: "#b0ffa0" }}>
                                    {netAprBase}%
                                  </b>{" "}
                                </h2>
                                {cubeType === 0 && (
                                  <h2
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    {/* *({t("exp. return performance example")}){" "} */}
                                    *({"exp. return performance example"}){" "}
                                  </h2>
                                )}
                                {cubeType === 2 && (
                                  <h2
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    {/* *({t("estimated profit")} @ {targetPrice}){" "} */}
                                    *({"estimated profit"} @ {targetPrice}){" "}
                                  </h2>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="logohyp">
                          <div id="" className="square hidden s-top"></div>
                          <div id="" className="square hidden s-bottom"></div>
                        </div>

                        <div className="tabs-content__left">
                          <h2
                            className=""
                            style={{
                              textAlign: "center",
                              color: "white",
                              marginBottom: "0.5em",
                            }}
                          >
                            {/* {t("Make it a")}{" "} */}
                            {"Make it a"}{" "}
                            <b style={{ color: "#ffa0f8" }}>HYPERCUBE</b>
                            <br />
                            <span
                              style={{ fontSize: "0.8em", color: "#ffa0f8" }}
                            >
                              (Coming soon)
                            </span>
                          </h2>
                          <ul className="list-unstyled tabs-content__points text-center">
                            <li>
                              <div className="text">
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {token?.symbol}{" "}
                                  {cubeType !== 1
                                    ? //   t("Exp. Return") : t("APR")}
                                      "Exp. Return"
                                    : "APR"}{" "}
                                  :{" "}
                                  <b style={{ color: "#ffa0f8" }}>
                                    {aprHyper}%
                                  </b>
                                  {cubeType !== 1 && <sup>*</sup>}
                                </h2>
                                {cubeType === 2 && (
                                  <h5
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    {/*{t("total liquidation price")} : { stopPrice }*/}
                                    {/* {t("Maximum loss")} : {maxCalculatedLoss} % */}
                                    {"Maximum loss"} : {maxCalculatedLoss} %
                                  </h5>
                                )}

                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  HYP{" "}
                                  {cubeType !== 1
                                    ? //   t("Exp. Return") : t("APR")}
                                      "Exp. Return"
                                    : "APR"}{" "}
                                  :{" "}
                                  <b style={{ color: "#f1ffa0" }}>{aprHYP}%</b>{" "}
                                </h2>
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {cubeType !== 1
                                    ? // ? t("Net Exp. Return")
                                      // : t("Net APR")}{" "}
                                      "Net Exp. Return"
                                    : "Net APR"}{" "}
                                  :{" "}
                                  <b style={{ color: "#b0ffa0" }}>
                                    {netAprHyper}%
                                  </b>{" "}
                                </h2>
                                <h5
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  ({token?.symbol} + HYP)
                                </h5>
                                {cubeType === 0 && (
                                  <h2
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    {/* *({t("exp. return performance example")}){" "} */}
                                    *({"exp. return performance example"}){" "}
                                  </h2>
                                )}
                                {cubeType === 2 && (
                                  <h2
                                    className="top"
                                    style={{
                                      textAlign: "center",
                                      color: "white",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    {/* *({t("estimated profit")} @ {targetPrice}){" "} */}
                                    {"estimated profit"}
                                  </h2>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      {web3Available ? (
                        <Link
                          to={`/trading/${tradingPath}/${id || 0}/${
                            index || 0
                          }/invest`}
                          className="thm-btn comment-form__btn"
                        >
                          {/* {t("Go to invest page")} */}
                          {"Go to invest page"}
                        </Link>
                      ) : (
                        <h2 style={{ color: "white" }}>
                          Please connect Web3 wallet to invest
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                {/* tab */}
                <div className="tab" id={"investors_" + index}>
                  <div className="tabs-content__inner">
                    <div className="row">
                      <div className="col-xl-4">
                        <h2
                          style={{ color: "white", textTransform: "uppercase" }}
                        >
                          {" "}
                          {/* {t("Investors List")}{" "} */}
                          {"Investors List"}{" "}
                        </h2>
                        <ul className="list-unstyled tabs-content__points">
                          <li>
                            <div className="text">
                              <p>
                                {/* {t("Total Investors Count")} :{" "} */}
                                {"Total Investors Count"} :{" "}
                                <b style={{ color: "white" }}>
                                  {investorsCount}
                                </b>
                                <br />
                                {/* {t("Total Invested Amount")} :{" "} */}
                                {"Total Invested Amount"} :{" "}
                                <b style={{ color: "white" }}>
                                  {UScurrencyFormatter(investorsAmount || 0, 4)}
                                </b>{" "}
                                {token?.symbol}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-xl-8">
                        {investorsList.map((item, count) => (
                          <div
                            className="row"
                            key={
                              "investor_" +
                              index +
                              "_" +
                              item.userAddress +
                              "_" +
                              count
                            }
                          >
                            <div className="col-xl-3">
                              <div className="text">
                                {/*item.userAddress*/}Investor
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <div className="text" style={{ float: "right" }}>
                                <b style={{ color: "white" }}>
                                  {UScurrencyFormatter(
                                    +item.currentInvestment || 0,
                                    4
                                  )}
                                </b>{" "}
                                {token?.symbol}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {cubeType === 0 && (
                  <div className="tab" id={"chart_" + index}>
                    <div className="tabs-content__inner">
                      <div className="row">
                        <div className="col-xl-4">
                          {chartLoaded ? (
                            <h2
                              style={{
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            >
                              {" "}
                              {/* {t("Share Price Chart")}{" "} */}
                              {"Share Price Chart"}{" "}
                            </h2>
                          ) : (
                            <h2
                              style={{
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            >
                              {" "}
                              {/* {t("Loading Share Price Chart")}{" "} */}
                              {"Loading Share Price Chart"}{" "}
                            </h2>
                          )}
                        </div>
                        {chartLoaded && (
                          <div className="col-xl-8" style={{ height: "30em" }}>
                            {/*<div id={'graph_' + index} />*/}
                            {
                              <ResponsiveContainer
                                width="100%"
                                height="100%"
                                minWith="1"
                                minHeight="1"
                              >
                                <AreaChart
                                  width={500}
                                  height={400}
                                  data={cubeChartDayReverse}
                                  margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <defs>
                                    <linearGradient
                                      id="areaColor"
                                      x1="0"
                                      y1="0"
                                      x2="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="5%"
                                        stopColor="#008ffb"
                                        stopOpacity={0.4}
                                      />
                                      <stop
                                        offset="95%"
                                        stopColor="#008ffb"
                                        stopOpacity={0.8}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <CartesianGrid vertical={false} />
                                  <XAxis dataKey="day" />
                                  <YAxis
                                    type="number"
                                    orientation="right"
                                    domain="['minData', 'MaxData']"
                                  />
                                  <Tooltip />
                                  <Area
                                    type="monotone"
                                    dataKey="sharePrice"
                                    stroke="#80c7fd"
                                    fillOpacity={1}
                                    fill="url(#areaColor)"
                                    strokeWidth={5}
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* tab */}
                <div className="tab" id={"about_" + index}>
                  <div className="tabs-content__inner">
                    <div className="row">
                      <div className="col-xl-4">
                        <h2
                          style={{ color: "white", textTransform: "uppercase" }}
                        >
                          {" "}
                          {/* {t("Cube Info")}{" "} */}
                          {"Cube Info"}{" "}
                        </h2>
                      </div>
                      <div className="col-xl-8">
                        <ul className="list-unstyled tabs-content__points">
                          <li>
                            <div className="text">
                              <p>
                                {/*{t("The key information for Fixed Income Cubes include:")} <br/>*/}
                                {
                                  "The key information for Fixed Income Cubes include:"
                                }{" "}
                                <br />
                                {/* {t("Asset")}:{" "} */}
                                {"Asset"}:{" "}
                                <b style={{ color: "white" }}>
                                  {token?.symbol}
                                </b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Asset: the asset deposited to earn returns on."
                                  //   )}
                                  tooltip={
                                    "Asset: the asset deposited to earn returns on."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                <br />
                                {/* {t("Invested Asset Amount")}:{" "} */}
                                {"Invested Asset Amount"}:{" "}
                                <b style={{ color: "white" }}>
                                  {cubeAssetAmount.toFixed(4)} {token?.symbol}
                                </b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Invested Asset Amount: the amount of asset currently invested in this cube."
                                  //   )}
                                  tooltip={
                                    "Invested Asset Amount: the amount of asset currently invested in this cube."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                <br />
                                {/* {t("Expiration")}:{" "} */}
                                {"Expiration"}:{" "}
                                <b style={{ color: "white" }}>
                                  {
                                    expirationDate.toGMTString() /*+ ' ' + expirationDate.toTimeString()*/
                                  }
                                </b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Expiration: the expiration date of the Cube."
                                  //   )}
                                  tooltip={
                                    "Expiration: the expiration date of the Cube."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                <br />
                                {/* {t("Entry Fee")}:{" "} */}
                                {"Entry Fee"}:{" "}
                                <b style={{ color: "white" }}>
                                  {entryFeeBase} %
                                </b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Entry Fee: the percentage fee deducted from the investment upon entry."
                                  //   )}
                                  tooltip={
                                    "Entry Fee: the percentage fee deducted from the investment upon entry."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                {cubeType !== 1 && (
                                  <>
                                    <br />
                                    {/* {t("Bid/Ask Spread")}:{" "} */}
                                    {"Bid/Ask Spread"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {/* 0.26 % ({t("may apply")}) */}
                                      0.26 % "may apply"
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Bid/Ask Spread: execution price is subject to a bid/ask spread of the used exchanges."
                                      //   )}
                                      tooltip={
                                        "Bid/Ask Spread: execution price is subject to a bid/ask spread of the used exchanges."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                  </>
                                )}
                                {cubeType === 0 && (
                                  <>
                                    <br />
                                    {/* {t("Performance Fee")}:{" "} */}
                                    {"Performance Fee"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {perfFeeBase} %
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Performance Fee: the percentage fee deducted from the performance return."
                                      //   )}
                                      tooltip={
                                        "Performance Fee: the percentage fee deducted from the performance return."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                  </>
                                )}
                                <br />
                                {/* {t("Return")}:{" "} */}
                                {"Return"}:{" "}
                                <b style={{ color: "white" }}>{aprBase} %</b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Return: the fixed annualized percentage return on the deposited asset."
                                  //   )}
                                  tooltip={
                                    "Return: the fixed annualized percentage return on the deposited asset."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                <br />
                                {/* {t("Lock Up Period")}:{" "} */}
                                {"Lock Up Period"}:{" "}
                                <b style={{ color: "white" }}>
                                  {lockupPeriodString}
                                </b>
                                <span
                                  style={{ marginLeft: "20px" }}
                                  //   tooltip={t(
                                  //     "Lock Up Period: the timelock of a user's investment, with early withdrawals sub-jected to a 20% fee."
                                  //   )}
                                  tooltip={
                                    "Lock Up Period: the timelock of a user's investment, with early withdrawals sub-jected to a 20% fee."
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle fa-lg"
                                    style={{ color: "white" }}
                                  ></i>
                                </span>
                                {cubeType === 2 && (
                                  <>
                                    <br />
                                    {/* {t("Risk Level")}:{" "} */}
                                    {"Risk Level"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {(riskLevel * 100).toFixed(2)} %
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Risk Level: the maximum percentage you can lose."
                                      //   )}
                                      tooltip={
                                        "Risk Level: the maximum percentage you can lose."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                    <br />
                                    {/* {t("Target Price")}:{" "} */}
                                    {"Target Price"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {targetPrice}
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Target Price: the Cube's closing price at which the user gets the maximum reward."
                                      //   )}
                                      tooltip={
                                        "Target Price: the Cube's closing price at which the user gets the maximum reward."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                    <br />
                                    {/* {t("Liquidation Price")}:{" "} */}
                                    {"Liquidation Price"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {stopPrice}
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Liquidation Price: the Cube's closing price at which the user lose his funds."
                                      //   )}
                                      tooltip={
                                        "Liquidation Price: the Cube's closing price at which the user lose his funds."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                    <br />
                                    {/* {t("Border Price")}:{" "} */}
                                    {"Border Price"}:{" "}
                                    <b style={{ color: "white" }}>
                                      {borderPrice}
                                    </b>
                                    <span
                                      style={{ marginLeft: "20px" }}
                                      //   tooltip={t(
                                      //     "Border Price: the lowest allowed entry price for long Cubes (or highest for short ones)."
                                      //   )}
                                      tooltip={
                                        "Border Price: the lowest allowed entry price for long Cubes (or highest for short ones."
                                      }
                                    >
                                      <i
                                        className="fa fa-info-circle fa-lg"
                                        style={{ color: "white" }}
                                      ></i>
                                    </span>
                                  </>
                                )}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* tab */}
                <div className="tab " id={"information_" + index}>
                  <div className="tabs-content__inner">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="tabs-content__left">
                          <ul className="list-unstyled tabs-content__points text-center">
                            <li>
                              <div className="text">
                                <h2
                                  className="top"
                                  style={{
                                    textAlign: "center",
                                    color: "white",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {/* {t("DISCLAIMER")} */}
                                  DISCLAIMER
                                </h2>
                              </div>
                            </li>
                            <li>
                              <div className="text">
                                <p>{disclaimer}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <ul className="list-unstyled tabs-content__points text-center">
                          <li>
                            <div className="text">
                              <h2
                                className="top"
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                  textTransform: "uppercase",
                                }}
                              >
                                {/* {t("Information")} */}
                                Information
                              </h2>
                            </div>
                          </li>
                          <li>
                            <div className="text">
                              <p>{info}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </section>
      </>
    );
  }
}

export default Card;
